var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { defineComponent, inject } from 'vue';
import LoadingOutlined from '@ant-design/icons-vue/LoadingOutlined';
import PropTypes from '../_util/vue-types';
import hasProp, { getOptionProps, getComponent } from '../_util/props-util';
import VcSwitch from '../vc-switch';
import Wave from '../_util/wave';
import { defaultConfigProvider } from '../config-provider';
import warning from '../_util/warning';
import { tuple, withInstall } from '../_util/type';
const Switch = defineComponent({
    name: 'ASwitch',
    __ANT_SWITCH: true,
    inheritAttrs: false,
    props: {
        prefixCls: PropTypes.string,
        // size=default and size=large are the same
        size: PropTypes.oneOf(tuple('small', 'default', 'large')),
        disabled: PropTypes.looseBool,
        checkedChildren: PropTypes.any,
        unCheckedChildren: PropTypes.any,
        tabindex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        checked: PropTypes.looseBool,
        defaultChecked: PropTypes.looseBool,
        autofocus: PropTypes.looseBool,
        loading: PropTypes.looseBool,
        onChange: PropTypes.func,
        onClick: PropTypes.func,
        'onUpdate:checked': PropTypes.func,
    },
    // emits: ['change', 'click', 'update:checked'],
    setup() {
        return {
            refSwitchNode: undefined,
            configProvider: inject('configProvider', defaultConfigProvider),
        };
    },
    created() {
        warning(hasProp(this, 'checked') || !('value' in this.$attrs), 'Switch', '`value` is not validate prop, do you mean `checked`?');
    },
    methods: {
        focus() {
            var _a;
            (_a = this.refSwitchNode) === null || _a === void 0 ? void 0 : _a.focus();
        },
        blur() {
            var _a;
            (_a = this.refSwitchNode) === null || _a === void 0 ? void 0 : _a.blur();
        },
        saveRef(c) {
            this.refSwitchNode = c;
        },
    },
    render() {
        const _a = getOptionProps(this), { prefixCls: customizePrefixCls, size, loading, disabled } = _a, restProps = __rest(_a, ["prefixCls", "size", "loading", "disabled"]);
        const { getPrefixCls } = this.configProvider;
        const prefixCls = getPrefixCls('switch', customizePrefixCls);
        const { $attrs } = this;
        const classes = {
            [$attrs.class]: $attrs.class,
            [`${prefixCls}-small`]: size === 'small',
            [`${prefixCls}-loading`]: loading,
        };
        const loadingIcon = loading ? <LoadingOutlined class={`${prefixCls}-loading-icon`}/> : null;
        const switchProps = Object.assign(Object.assign(Object.assign({}, restProps), $attrs), { prefixCls,
            loadingIcon, checkedChildren: getComponent(this, 'checkedChildren'), unCheckedChildren: getComponent(this, 'unCheckedChildren'), disabled: disabled || loading, class: classes, ref: this.saveRef });
        return (<Wave insertExtraNode>
        <VcSwitch {...switchProps}/>
      </Wave>);
    },
});
export default withInstall(Switch);
