var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { defineComponent } from 'vue';
import T, { defaultTableProps } from './Table';
import { getOptionProps, getKey, getPropsData, getSlot } from '../_util/props-util';
const Table = defineComponent({
    name: 'ATable',
    Column: T.Column,
    ColumnGroup: T.ColumnGroup,
    inheritAttrs: false,
    props: defaultTableProps,
    methods: {
        normalize(elements = []) {
            const columns = [];
            elements.forEach(element => {
                var _a, _b, _c, _d;
                if (!element) {
                    return;
                }
                const key = getKey(element);
                const style = ((_a = element.props) === null || _a === void 0 ? void 0 : _a.style) || {};
                const cls = ((_b = element.props) === null || _b === void 0 ? void 0 : _b.class) || '';
                const props = getPropsData(element);
                const _e = element.children || {}, { default: children } = _e, restSlots = __rest(_e, ["default"]);
                const column = Object.assign(Object.assign(Object.assign({}, restSlots), props), { style, class: cls });
                if (key) {
                    column.key = key;
                }
                if ((_c = element.type) === null || _c === void 0 ? void 0 : _c.__ANT_TABLE_COLUMN_GROUP) {
                    column.children = this.normalize(typeof children === 'function' ? children() : children);
                }
                else {
                    const customRender = (_d = element.children) === null || _d === void 0 ? void 0 : _d.default;
                    column.customRender = column.customRender || customRender;
                }
                columns.push(column);
            });
            return columns;
        },
        updateColumns(cols = []) {
            const columns = [];
            const { $slots } = this;
            cols.forEach(col => {
                const { slots = {} } = col, restProps = __rest(col, ["slots"]);
                const column = Object.assign({}, restProps);
                Object.keys(slots).forEach(key => {
                    const name = slots[key];
                    if (column[key] === undefined && $slots[name]) {
                        column[key] = $slots[name];
                    }
                });
                // if (slotScopeName && $scopedSlots[slotScopeName]) {
                //   column.customRender = column.customRender || $scopedSlots[slotScopeName]
                // }
                if (col.children) {
                    column.children = this.updateColumns(column.children);
                }
                columns.push(column);
            });
            return columns;
        },
    },
    render() {
        const { normalize, $slots } = this;
        const props = Object.assign(Object.assign({}, getOptionProps(this)), this.$attrs);
        const columns = props.columns ? this.updateColumns(props.columns) : normalize(getSlot(this));
        let { title, footer } = props;
        const { title: slotTitle, footer: slotFooter, expandedRowRender = props.expandedRowRender, expandIcon, } = $slots;
        title = title || slotTitle;
        footer = footer || slotFooter;
        const tProps = Object.assign(Object.assign({}, props), { columns,
            title,
            footer,
            expandedRowRender, expandIcon: this.$props.expandIcon || expandIcon });
        return <T {...tProps} ref="table"/>;
    },
});
/* istanbul ignore next */
Table.install = function (app) {
    app.component(Table.name, Table);
    app.component(Table.Column.name, Table.Column);
    app.component(Table.ColumnGroup.name, Table.ColumnGroup);
    return app;
};
export default Table;
